/* Defining basic CSS styles */

* {
  margin: 0;
  padding: 0;
  font: inherit;
  box-sizing: border-box;
  scroll-behavior: smooth;
  vertical-align: baseline;
}

body {
  background: #f4f4f4;
  padding-top: 3.5em;
  -ms-overflow-style: scrollbar;
  -webkit-text-size-adjust: none;
}

body,
input,
select,
textarea {
  color: #646464;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 14pt;
  font-weight: 400;
  line-height: 1.75;

  @media screen and (max-width: 1680px) {
    font-size: 12pt;
  }
}

a {
  text-decoration: none;
  color: inherit;
}


/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
} */

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */